import React from "react";
import AlertPropsTypes from "./types";

export default function Alert({ variant, children }: AlertPropsTypes) {
  if (children === null || children === undefined) {
    return null;
  }

  return (
    <div className={`alert alert-${variant}`}>
      {children}
    </div>
  )
}
